export const kta = {
  state: {
    cardType: null,
    cardId: null,
    nrp: null,
  },
  mutations: {
    setCardType(state, value) {
      state.cardType = value;
    },
    setCardId(state, value) {
      state.cardId = value;
    },
    setNrp(state, value) {
      state.nrp = value;
    },
  },
};