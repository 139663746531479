// import moment from "moment";
import { createWebHistory, createRouter } from "vue-router";
// import Home from "./components/Home.vue";
import Register from "./components/Register.vue";
import authService from "./services/auth.service";
// import Login from "./components/Login.vue";

const routes = [
  {
    path: "/",
    name: "home",
    // component: Home,
    meta: {
      guestOnly: true,
    },
    component: () => import("../src/pages/LoginPage"),
  },
  {
    path: "/tes",
    name: "tes-page",
    component: () => import("../src/pages/TesPage"),
  },
  {
    path: "/tes-camera",
    name: "tes-camera",
    component: () => import("../src/pages/TesCamera.vue"),
  },

  {
    path: "/s/:id",
    name: "slink",
    component: () => import("../src/pages/ShortenLink"),
  },
  // {
  //   path: "/login-app",
  //   component: Login,
  // },
  {
    path: "/login",
    name: "login",
    meta: {
      guestOnly: true,
    },
    component: () => import("../src/pages/LoginPage"),
  },
  {
    path: "/register",
    name: "register",
    meta: {
      guestOnly: true,
    },
    component: Register,
  },
  {
    path: "/",
    name: "layanan",
    component: () => import("../src/layouts/Blank.vue"),
    children: [
      {
        path: "/services/:token",
        name: "service-token",
        component: () => import("./pages/layanan/Index.vue"),
      },
      {
        path: "/services/submission/history/:token",
        name: "service-submission-history",
        component: () => import("./pages/layanan/ktp-ktak/History.vue"),
      },
      {
        path: "/services/submission/:token",
        name: "service-submission",
        component: () => import("./pages/layanan/ktp-ktak/Submission.vue"),
      },
      {
        path: "/services/personal/history/:token",
        name: "service-personal",
        component: () => import("./pages/layanan/personal/Personal.vue"),
      },
      {
        path: "/services/personal/edit/:token",
        name: "service-personal-edit",
        component: () => import("./pages/layanan/personal/PersonalForm.vue"),
      },
      {
        path: "/services/personal/edit-family/:token",
        name: "service-personal-edit-family",
        component: () =>
          import("./pages/layanan/personal/PersonalFamilyForm.vue"),
      },

      {
        path: "/services/personal/photo",
        name: "service-personal-photo",
        component: () => import("./pages/layanan/personal/PersonalPhoto.vue"),
      },
      {
        path: "/services/report",
        name: "service-report",
        component: () => import("./pages/layanan/Report.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "guest",
    component: () => import("../src/layouts/GuestLayout.vue"),
    children: [
      {
        path: "/kta/check",
        name: "check-kta",
        component: () => import("./pages/validate/CheckKta.vue"),
      },
      {
        path: "/kta/verification",
        name: "check-kta-valid",
        component: () => import("./pages/validate/ValidateKta.vue"),
      },
      {
        path: "/audit/:id",
        name: "audit",
        // component: () => import("../src/pages/AuditView"),
        component: () => import("../src/pages/kta/Verification.vue"),
      },

      // {
      //   path: "/kta/verification/:status",
      //   name: "check-kta-valid",
      //   component: () => import("./pages/validate/ValidateKta.vue"),
      // },
      // {
      //   path: "/kta/verification/:status/:serial",
      //   name: "check-kta-valid-detail",
      //   component: () => import("./pages/validate/ValidateKta.vue"),
      //   // component: () => import("./pages/validate/ValidateKtaDetail.vue"),
      // },
    ],
  },
  {
    path: "/",
    name: "verification",
    meta: {
      isAuth: true,
    },
    component: () => import("../src/layouts/DashboardLayout"),
    children: [
      {
        path: "/verification/dashboard",
        name: "verification-dashboard",
        meta: {
          title: "Dashboard Admin Verifikasi",
          subTitle: ["Verifikasi", "Dashboard"],
        },
        component: () => import("../src/pages/verification/Index.vue"),
      },
      {
        path: "/verification/upload",
        name: "verification-upload",
        meta: {
          title: "Upload Data Verifikasi",
          subTitle: ["Verifikasi", "Upload"],
        },
        component: () => import("../src/pages/verification/UploadData.vue"),
      },
      {
        path: "/verification/verify",
        name: "verification-verify",
        meta: {
          title: "Verifikasi Data",
          subTitle: ["Verifikasi", "Data"],
        },
        component: () => import("../src/pages/verification/VerifikasiData.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "admin",
    meta: {
      isAuth: true,
    },
    component: () => import("../src/layouts/DashboardLayout"),
    children: [
      {
        path: "/admin/dashboard",
        name: "dashboard",
        meta: {
          title: "Dashboard",
          subTitle: ["Admin", "Dashboard"],
        },
        component: () => import("../src/pages/DashboardPage"),
      },
      // manage user
      {
        path: "/admin/user",
        name: "user-list",
        meta: {
          title: "Manage User",
          subTitle: ["Admin", "User"],
        },
        component: () => import("../src/pages/admin/user/UserList"),
      },
      {
        path: "/admin/user-create",
        name: "user-create",
        meta: {
          title: "Create User",
          subTitle: ["Admin", "User", "Create"],
        },
        component: () => import("../src/pages/admin/user/UserForm"),
      },
      {
        path: "/admin/:id/user-update",
        name: "user-update",
        meta: {
          title: "Update User",
          subTitle: ["Admin", "User", "Update"],
        },
        component: () => import("../src/pages/admin/user/UserForm"),
      },
      // manage role
      {
        path: "/admin/role",
        name: "role-list",
        meta: {
          title: "Manage Role",
          subTitle: ["Admin", "Role"],
        },
        component: () => import("../src/pages/admin/role/RoleList"),
      },
      {
        path: "/admin/role-create",
        name: "role-create",
        meta: {
          title: "Create Role",
          subTitle: ["Admin", "Role", "Create"],
        },
        component: () => import("../src/pages/admin/role/RoleForm"),
      },
      {
        path: "/admin/:id/role-update",
        name: "role-update",
        meta: {
          title: "Manage Role",
          subTitle: ["Admin", "Role", "Update"],
        },
        component: () => import("../src/pages/admin/role/RoleForm"),
      },
      // manage permission
      {
        path: "/admin/permission",
        name: "permission-list",
        meta: {
          title: "Manage Permission",
          subTitle: ["Admin", "Permission"],
        },
        component: () => import("../src/pages/admin/permission/PermissionList"),
      },
      {
        path: "/admin/permission-create",
        name: "permission-create",
        meta: {
          title: "Create Permission",
          subTitle: ["Admin", "Permission", "Create"],
        },
        component: () => import("../src/pages/admin/permission/PermissionForm"),
      },
      {
        path: "/admin/:id/permission-update",
        name: "permission-update",
        meta: {
          title: "Update Permission",
          subTitle: ["Admin", "Permission", "Update"],
        },
        component: () => import("../src/pages/admin/permission/PermissionForm"),
      },
      // manage menu
      {
        path: "/admin/menu",
        name: "menu-list",
        meta: {
          title: "Manage Menu",
          subTitle: ["Admin", "Menu"],
        },
        component: () => import("../src/pages/admin/menu/MenuList"),
      },
      {
        path: "/admin/menu-create",
        name: "menu-create",
        meta: {
          title: "Create Menu",
          subTitle: ["Admin", "Menu", "Create"],
        },
        component: () => import("../src/pages/admin/menu/MenuForm"),
      },
      {
        path: "/admin/:id/menu-update",
        name: "menu-update",
        meta: {
          title: "Update Menu",
          subTitle: ["Admin", "Menu", "Update"],
        },
        component: () => import("../src/pages/admin/menu/MenuForm"),
      },
      // manage client-app
      {
        path: "/admin/client-app",
        name: "client-list",
        meta: {
          title: "Manage Client App",
          subTitle: ["Admin", "Client App"],
        },
        component: () => import("../src/pages/admin/client-app/Index"),
      },
      {
        path: "/admin/client-app",
        name: "client-create",
        meta: {
          title: "Create Client App",
          subTitle: ["Admin", "Client App", "Create"],
        },
        component: () => import("../src/pages/admin/client-app/Form"),
      },
      {
        path: "/admin/:id/client-app-update",
        name: "client-update",
        meta: {
          title: "Update CLient App",
          subTitle: ["Admin", "CLient App", "Update"],
        },
        component: () => import("../src/pages/admin/client-app/Form"),
      },
      // manage satker
      {
        path: "/admin/satker",
        name: "satker-list",
        meta: {
          title: "Manage Satker",
          subTitle: ["Admin", "Satker"],
        },
        component: () => import("../src/pages/admin/satker/Index"),
      },
      {
        path: "/admin/satker",
        name: "satker-create",
        meta: {
          title: "Create Satker",
          subTitle: ["Admin", "Satker", "Create"],
        },
        component: () => import("../src/pages/admin/satker/Form"),
      },
      {
        path: "/admin/satker/upload",
        name: "satker-upload",
        meta: {
          title: "Upload Satker",
          subTitle: ["Admin", "Satker", "Upload"],
        },
        component: () => import("../src/pages/admin/satker/Upload"),
      },
      {
        path: "/admin/satker/mapping",
        name: "satker-mapping",
        meta: {
          title: "Mapping Satker",
          subTitle: ["Admin", "Satker", "Mapping"],
        },
        component: () => import("../src/pages/admin/satker/Mapping"),
      },
      {
        path: "/admin/:id/satker-update",
        name: "satker-update",
        meta: {
          title: "Update Satker",
          subTitle: ["Admin", "Satker", "Update"],
        },
        component: () => import("../src/pages/admin/satker/Form"),
      },
      // manage serial number
      {
        path: "/admin/serial",
        name: "serial-list",
        meta: {
          title: "Manage Serial NUmber",
          subTitle: ["Admin", "Serial NUmber"],
        },
        component: () => import("../src/pages/admin/serial/Index"),
      },
      {
        path: "/admin/serial",
        name: "serial-create",
        meta: {
          title: "Create Serial Number",
          subTitle: ["Admin", "Serial Number", "Create"],
        },
        component: () => import("../src/pages/admin/serial/Form"),
      },
      {
        path: "/admin/:id/serial-update",
        name: "serial-update",
        meta: {
          title: "Update Serial Number",
          subTitle: ["Admin", "Serial Number", "Update"],
        },
        component: () => import("../src/pages/admin/serial/Form"),
      },
      {
        path: "/admin/serial/upload",
        name: "serial-upload",
        meta: {
          title: "Upload Serial Number",
          subTitle: ["Admin", "Serial Number", "Upload"],
        },
        component: () => import("../src/pages/admin/serial/Upload"),
      },
      // manage jabatan
      {
        path: "/admin/position",
        name: "position-list",
        meta: {
          title: "Manage Jabatan",
          subTitle: ["Admin", "Jabatan"],
        },
        component: () => import("../src/pages/admin/jabatan/Index"),
      },
      {
        path: "/admin/position",
        name: "position-create",
        meta: {
          title: "Create Jabatan",
          subTitle: ["Admin", "Jabatan", "Create"],
        },
        component: () => import("../src/pages/admin/jabatan/Form"),
      },
      {
        path: "/admin/:id/postion-update",
        name: "position-update",
        meta: {
          title: "Update Jabatan",
          subTitle: ["Admin", "Jabatan", "Update"],
        },
        component: () => import("../src/pages/admin/jabatan/Form"),
      },
      // {
      //   path: "/admin/postion/upload",
      //   name: "postion-upload",
      //   meta: {
      //     title: "Upload Jabatan",
      //     subTitle: ["Admin", "Jabatan", "Upload"],
      //   },
      //   component: () => import("../src/pages/admin/jabatan/Upload"),
      // },
      // manage pangkat
      {
        path: "/admin/rank",
        name: "rank-list",
        meta: {
          title: "Manage Pangkat",
          subTitle: ["Admin", "Pangkat"],
        },
        component: () => import("../src/pages/admin/pangkat/Index"),
      },
      {
        path: "/admin/rank",
        name: "rank-create",
        meta: {
          title: "Create Pangkat",
          subTitle: ["Admin", "Pangkat", "Create"],
        },
        component: () => import("../src/pages/admin/pangkat/Form"),
      },
      {
        path: "/admin/:id/rank-update",
        name: "rank-update",
        meta: {
          title: "Update Pangkat",
          subTitle: ["Admin", "Pangkat", "Update"],
        },
        component: () => import("../src/pages/admin/pangkat/Form"),
      },
      // {
      //   path: "/admin/rank/upload",
      //   name: "rank-upload",
      //   meta: {
      //     title: "Upload Pangkat",
      //     subTitle: ["Admin", "Pangkat", "Upload"],
      //   },
      //   component: () => import("../src/pages/admin/pangkat/Upload"),
      // },
      // manage card-type
      {
        path: "/admin/card-type",
        name: "card-type-list",
        meta: {
          title: "Manage Card  Type",
          subTitle: ["Admin", "Card Type"],
        },
        component: () => import("../src/pages/admin/card-type/Index.vue"),
      },
      {
        path: "/admin/card-type-create",
        name: "card-type-create",
        meta: {
          title: "Create card-type",
          subTitle: ["Admin", "Card Type", "Create"],
        },
        component: () => import("../src/pages/admin/card-type/Form.vue"),
      },
      {
        path: "/admin/:id/card-type-update",
        name: "card-type-update",
        meta: {
          title: "Update Card Type",
          subTitle: ["Admin", "Card Type", "Update"],
        },
        component: () => import("../src/pages/admin/card-type/Form.vue"),
      },
      // manage esign
      {
        path: "/admin/esign",
        name: "esign-list",
        meta: {
          title: "Manage Esign",
          subTitle: ["Admin", "E-Sign"],
        },
        component: () => import("../src/pages/admin/esign/Index"),
      },
      {
        path: "/admin/esign-create",
        name: "esign-create",
        meta: {
          title: "Create Esign",
          subTitle: ["Admin", "E-Sign", "Create"],
        },
        component: () => import("../src/pages/admin/esign/Form"),
      },
      {
        path: "/admin/esign/map",
        name: "esign-map",
        meta: {
          title: "Mapping Esign",
          subTitle: ["Admin", "E-Sign", "Mapping"],
        },
        component: () => import("../src/pages/admin/esign/IndexMapping"),
      },
      {
        path: "/admin/esign/test",
        name: "esign-test",
        meta: {
          title: "Tes Esign",
          subTitle: ["Admin", "E-Sign", "Test"],
        },
        component: () => import("../src/pages/admin/esign/Test"),
      },
      {
        path: "/admin/:id/esign-update",
        name: "esign-update",
        meta: {
          title: "Update Esign",
          subTitle: ["Admin", "E-Sign", "Update"],
        },
        component: () => import("../src/pages/admin/esign/Form"),
      },
      {
        path: "/upload",
        name: "upload-data",
        component: () => import("../src/pages/UploadData.vue"),
      },
      {
        path: "/admin/esign-service",
        name: "esign-service",
        meta: {
          title: "Manage Esign Service",
          subTitle: ["Admin", "Manage E-Sign Service"],
        },
        component: () => import("../src/pages/admin/esign-service/Index"),
      },
      {
        path: "/admin/esign-service-create",
        name: "esign-service-create",
        meta: {
          title: "Create Esign Service",
          subTitle: ["Admin", "Create E-Sign Service"],
        },
        component: () => import("../src/pages/admin/esign-service/Form"),
      },
      {
        path: "/admin/esign-service/:id/update",
        name: "esign-service-update",
        meta: {
          title: "Update Esign Service",
          subTitle: ["Admin", "Update E-Sign Service"],
        },
        component: () => import("../src/pages/admin/esign-service/Form"),
      },
      {
        path: "/admin/esign-service/:serviceId/user-create",
        name: "esign-service-create-user",
        meta: {
          title: "Create Esign User",
          subTitle: ["Admin", "Create E-Sign User"],
        },
        component: () => import("../src/pages/admin/esign-service/FormUser"),
      },
      {
        path: "/admin/esign-service/:serviceId/:id/user-update",
        name: "esign-service-update-user",
        meta: {
          title: "Update Esign User",
          subTitle: ["Admin", "Update E-Sign User"],
        },
        component: () => import("../src/pages/admin/esign-service/FormUser"),
      },
      {
        path: "/admin/esign-service/:serviceId/tte-create",
        name: "esign-service-create-tte",
        meta: {
          title: "Create Esign Request",
          subTitle: ["Admin", "Create E-Sign Request"],
        },
        component: () => import("../src/pages/admin/esign-service/FormTte"),
      },
      {
        path: "/admin/esign-service/:serviceId/:requestId/request",
        name: "esign-service-request-detail",
        meta: {
          title: "Detail Job Esign Request",
          subTitle: ["Admin", "Process E-Sign Request"],
        },
        component: () =>
          import("../src/pages/admin/esign-service/JobRequest.vue"),
      },
      {
        path: "/admin/esign-service/:id/detail",
        name: "esign-service-detail",
        meta: {
          title: "Detail Esign Service",
          subTitle: ["Admin", "Detail E-Sign Service"],
        },
        component: () => import("../src/pages/admin/esign-service/Detail"),
      },
    ],
  },
  {
    path: "/",
    meta: {
      isAuth: true,
    },
    component: () => import("../src/layouts/DashboardLayout"),
    children: [
      {
        path: "/profile",
        name: "profile",
        component: () => import("../src/pages/Profile"),
      },
      {
        path: "/kta/submission",
        name: "submission-kta",
        component: () => import("../src/pages/kta/SubmissionList"),
      },
      {
        path: "/kta/submission/upload",
        name: "submission-kta-upload",
        component: () => import("../src/pages/kta/SubmissionUpload"),
      },
      {
        path: "/kta/submission/detail/:id",
        name: "submission-kta-detail",
        component: () => import("../src/pages/kta/SubmissionDetail"),
      },
      {
        path: "/kta/submission-form",
        name: "submission-kta-form",
        component: () => import("../src/pages/kta/SubmissionForm"),
      },

      {
        path: "/kta/monitoring-serial-number",
        name: "serial-number",
        component: () => import("../src/pages/kta/SerialNumberList"),
      },
      {
        path: "/kta/sisfopers",
        name: "sisfopers",
        meta: {
          title: "Data Sisfopers",
          subTitle: ["Sisfopers", "KTA"],
        },
        component: () => import("../src/pages/kta/SisfopersList"),
      },
      {
        path: "/kta/person/:personId",
        name: "person-detail",
        meta: {
          title: "Data Personil",
          subTitle: ["Personil", "Detail"],
        },
        component: () => import("../src/pages/kta/PersonDetail"),
      },
      {
        path: "/kta/verifikasi/:personId",
        name: "verifikasi",
        meta: {
          title: "Verifikasi Data",
          subTitle: ["Personil", "Verifikasi"],
        },
        component: () => import("../src/pages/kta/Verification"),
      },
      {
        path: "/kta/approval",
        name: "approval",
        meta: {
          title: "Daftar Approval & TTE Cetak KTA",
          subTitle: ["Admin", "Approval & TTE"],
        },
        component: () => import("../src/pages/kta/ApprovalsList"),
      },
      {
        path: "/kta/monitoring",
        name: "monitoring",
        component: () => import("../src/pages/kta/MonitoringList"),
      },
      {
        path: "/kta-list",
        name: "kta",
        component: () => import("../src/pages/kta/KtaList"),
      },
      {
        path: "/kta-detail/:id",
        name: "kta-detail",
        component: () => import("../src/pages/kta/KtaDetail"),
      },
    ],
  },
  {
    path: "/:catchAll(.*)*",
    component: () => import("../src/pages/404"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user");

  // TODO: CHECK EXPIRED TOKEN
  if (loggedIn) {
    const { expiredAt } = JSON.parse(loggedIn);
    const exp = new Date();
    const exp2 = new Date(expiredAt);
    if (exp.getTime() > exp2.getTime()) {
      console.log("Token expired, do logout");
      authService.refreshToken();
      // authService.logout();
    }
    // else {
    // }
  }

  if (to.meta.isAuth && loggedIn === null) {
    next({ name: "login" });
  } else if (to.meta.guestOnly && loggedIn !== null) {
    next({ name: "dashboard" });
  } else {
    next();
  }
});

export default router;
