import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import "./assets/plugins.bundle";
import "bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { FontAwesomeIcon } from "./plugins/font-awesome";
import VueExcelEditor from "vue3-excel-editor";
import vSelect from "vue-select";
import Vue3Toastify from "vue3-toastify";
import "vue3-toastify/dist/index.css";

createApp(App)
  .use(router)
  .use(store)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("v-select", vSelect)
  .use(VueExcelEditor)
  .use(VueSweetalert2)
  .use(Vue3Toastify, {
    autoClose: 3000,
  })
  .mount("#app");
