import api from "./api";
import TokenService from "./token.service";

function addMinutes(date, minutes) {
  date.setMinutes(date.getMinutes() + minutes);
  return date;
}

class AuthService {
  login(user) {
    return api
      .post("auth/signin", {
        email: user.email,
        password: user.password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          const { data } = response;
          return api
            .get(`auth/profile`, {
              headers: { Authorization: `Bearer ${data.accessToken}` },
            })
            .then((profileResponse) => {
              const profileData = profileResponse.data.data;
              const currentTime = new Date();
              data.expiredAt = addMinutes(currentTime, data.expiresIn / 60);
              const userData = { ...data, ...profileData };
              TokenService.setUser(userData);
              // localStorage.setItem("user", JSON.stringify(userData));
              return userData;
            });
        }
      });
  }

  logout() {
    TokenService.removeUser();
    // localStorage.removeItem("user");
  }

  register(user) {
    return api.post("auth/signup", {
      username: user.username,
      email: user.email,
      password: user.password,
    });
  }

  refreshToken() {
    const token = TokenService.getLocalRefreshToken();
    api
      .post("auth/refreshtoken", {
        refreshToken: token,
      })
      .then(({ data }) => {
        const { accessToken } = data;
        TokenService.updateLocalAccessToken(accessToken);
      });
  }
}

export default new AuthService();
